import React from 'react';

import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/core';
import { CheckCircle } from '@hbf/icons/brand-regular';

import { RenderDangerously } from 'ha/helpers/RenderDangerously';
import { AuthPageQueryActions, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { useIntl } from 'ha/i18n';

import { PageDrawer } from '../../../../SearchRedesign/components/PageDrawer/PageDrawer';

import { SearchAlertFilterDetails } from './SearchAlertFilterDetails';
import { SearchAlertsExperimentProps, TypographyProps } from './types';

const useStyles = makeStyles()(theme => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/6'),
  },
  colorSuccess: {
    color: theme.palette.success.dark,
  },
  link: {
    fontWeight: 'bolder',
  },
  checkCircle: {
    width: 40,
    height: 40,
  },
}));

export const SearchAlertsSubmitSuccess = ({
  form,
}: Pick<SearchAlertsExperimentProps, 'form'>) => {
  const { T, urlResolver } = useIntl();
  const { classes, cx } = useStyles();

  return (
    <div data-test-locator="SearchAlertsDrawerWithoutAuth.SearchAlertsSubmitSuccess">
      <PageDrawer.Header>
        {T('unauthorised_alerts.modal.get_alerts')}
      </PageDrawer.Header>
      <PageDrawer.Content>
        <Box className={classes.contentBox}>
          <Box
            display="grid"
            className={classes.colorSuccess}
            gap={theme => theme.spacing(2)}
          >
            <CheckCircle
              color="success"
              className={cx(classes.colorSuccess, classes.checkCircle)}
            />
            <Typography variant="heading/desktop/h4-semibold">
              {T('unauthorised_alerts.modal.get_alerts.done_heading')}
            </Typography>
          </Box>
          <Typography>
            {T(
              'unauthorised_alerts.modal.get_alerts.done_summary',
              form.values.email,
            )}
          </Typography>
          <Box display="grid" gap={theme => theme.spacing(2)}>
            <SearchAlertFilterDetails />
          </Box>
          <Box>
            <RenderDangerously<TypographyProps>
              renderWith={Typography}
              variant="body/lg-regular"
            >
              {T('unauthorised_alerts.modal.get_alerts.done_cta', {
                link_prefix: `<a href="${urlResolver.getOAuthSignupUrl(
                  undefined,
                  {
                    target: AuthPageTarget.Tenant,
                    action: AuthPageQueryActions.CREATE_SEARCH_ALERT,
                  },
                )}" class=${classes.link}>`,
                link_suffix: '</a>',
              })}
            </RenderDangerously>
          </Box>
        </Box>
      </PageDrawer.Content>
    </div>
  );
};
